import { actionConfig } from "../../configuration";
import { actionType } from "../types/types"

export const getDashboardListACtion = (agentId,teamObj) =>{

    return async function(dispatch,getState){
        
        const response = await fetch(`${actionConfig.REACT_APP_URL}listdashboardaction?agentId=${agentId}&teamObj=${teamObj}`);
        const dataxs = await response.json();
        const finalDataMy = dataxs;
        dispatch(
            {
                type:actionType.DASHBOARDLISTACTION,
                payload:finalDataMy,
            }
        )

    }
    
}

export function loadingToggleAction (status){
    return {
        type:actionType.LOADINGTOGGLEACTION,
        payload:status
    }
}
