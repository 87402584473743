import { actionConfig } from "../../configuration";
import { actionType } from "../types/types"

export const ImportLeadAction = (leadStatus,page,limit,ProjectFilter,agentId) =>{
    return async function (dispatch,getState){
        const response = await fetch(`${actionConfig.REACT_APP_URL}importleads?leadstatus=${leadStatus}&ProjectFilter=${ProjectFilter}&agentId=${agentId}&orderBy=id&orderType=desc&page=${page}&perPage=${limit}`);
        const dataxs = await response.json();
        const GetArray = dataxs;
        dispatch(
            {
                type:actionType.IMPORTLEADSACTION,
                payload:GetArray,
            }
        )

    }
}