export const actionConfig ={
    REACT_APP_URL:"https://crmapi.pakrealestatecrm.com/api/",
    REACT_APP_MAIN:"https://crmapi.pakrealestatecrm.com/",
    REACT_ASSET_URL:"https://crmnew.pakrealestatecrm.com/",
    REACT_DOWNLOAD_URL:"https://www.redbox.estate/document/",
}

// export const actionConfig ={
//     REACT_APP_URL:"https://crmapi.sellmore.pk/api/",
//     REACT_APP_MAIN:"https://crmapi.sellmore.pk/",
//     REACT_ASSET_URL:"https://crm.sellmore.pk/",
//     REACT_DOWNLOAD_URL:"https://www.redbox.estate/document/",
// }

// export const actionConfig ={
//     REACT_APP_URL:"https://finapi.sellmore.pk/api/",
//     REACT_APP_MAIN:"https://finapi.sellmore.pk/",
//     REACT_ASSET_URL:"https://finapp.sellmore.pk/",
//     REACT_DOWNLOAD_URL:"https://www.redbox.estate/document/",
// }

// export const actionConfig ={
//     REACT_APP_URL:"https://demoapi.redbox.com.pk/api/",
//     REACT_APP_MAIN:"https://demoapi.redbox.com.pk/",
//     REACT_ASSET_URL:"https://crmdemo.redbox.com.pk/",
// }